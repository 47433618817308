import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { useAnalytics } from "core/hooks"
import { PageContainer, breakpoint } from "core/layout"
import { PageTransition } from "core/components"
import { Heading, ScrollToTop, CategoryFilter } from "elements"
import { WorksGrid } from "modules"
import PageLayout from "../templates/PageLayout"
import { graphql } from "gatsby"

const MarginBox = styled.div`
  margin: ${rem(40)} 0;

  ${breakpoint.tablet`
    margin: ${rem(80)} 0;
  `};
`

export default function AllWorks({ location, data }) {
  useAnalytics({
    title: "all-works",
    path: location.pathname,
  })

  const [category, setCategory] = React.useState(-1)
  const filteredCards = React.useMemo(() => {
    if (data) {
      const cards = data.strapi.workShowcases
      return category > -1
        ? cards.filter(card => {
            const work = card.work
            if (work.category) {
              return work.category.id === category
            } else if (work.categories && Array.isArray(work.categories)) {
              return card.work.categories.some(c => c.id === category)
            }
            return false
          })
        : cards
    }
    return []
  }, [data, category])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [category])

  const workpage = data.strapi.workpage
  if (!workpage) {
    return (
      <PageLayout>
        <PageTransition>
          <PageContainer>
            <Heading>Empty page</Heading>
          </PageContainer>
        </PageTransition>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <PageTransition>
        <CategoryFilter
          categories={data.strapi.categories}
          activeCategory={category}
          onChange={id => {
            setCategory(id)
          }}
        />

        <PageContainer>
          <MarginBox>
            <WorksGrid title={workpage.title} cards={filteredCards} />
          </MarginBox>
          <ScrollToTop currentId="works" />
        </PageContainer>
      </PageTransition>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query WorkpageQuery {
    strapi {
      workpage {
        id
        title
      }

      workShowcases(sort: "order:desc") {
        id
        slug
        title
        description
        color
        showcaseImage {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        showcaseImageMobile {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        work {
          categories {
            id
            category
          }
        }
      }

      categories {
        id
        category
      }
    }
  }
`
